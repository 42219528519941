import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from './Media';

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Team Leader.", "Full Stack Developer.", "Mobile App Developer","Database Engineer"],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">"I WANT TO MAKE THINGS THAT MAKE A DIFFERENCE 👀🙋‍♂️"</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Gopal Chudal</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        "I am Gopal Chudal. I have 10+ years of experience as a software engineer, entrepreneur, and team builder. I'm motivated by product success and business growth, not technology for technology's sake. I'm always interested in becoming a better technology leader, and meeting interesting people in NYC. See my LinkedIn profile for more career details."
        </p>
      </div>
     {/* Media */}
     <Media />
    </div>
  );
}

export default LeftBanner
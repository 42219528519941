import {  FaBrain,  FaMobileAlt} from "react-icons/fa";
import {  SiDatabricks, SiProgress, SiTableau} from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { MdAnalytics } from "react-icons/md";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <SiTableau />,
    title: "Project Management",
    des: "Real-life experience of managing a complete software project cycle.",
  },
  {
    id: 2,
    icon: <CgWebsite />,
    title: "Web Development",
    des: "Design real World Web Applications, eCommerse, Business and all types of websites.",
  },
  {
    id: 3,
    icon: <FaMobileAlt />,
    title: "Mobile App",
    des: "Design and Develop Real World Mobile Applications using Flutter, React and Java",
  },
  {
    id: 4,
    icon: <SiProgress />,
    title: "Web Solution",
    des: "Host and Manage Website, Search Engine Optimization, Content Management System",
  },
  {
    id: 5,
    icon: <SiDatabricks />,
    title: "Database Solutions",
    des: "Design, Manage, Migrate and Optimize Database",
  },
  {
    id: 6,
    icon: <MdAnalytics />,
    title: "Api and Web Services",
    des: "Design and Develop Api and Web Services",
  },
];
 
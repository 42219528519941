import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree,projectFour,projectFive,projectSix} from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="Check these out."
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="FinBanking"
          des=" A Complete Co-Operative Financial Solution designed for Co-Operative and Microfinance."
          src={projectOne}
          websiteLink="#"

        />
        <ProjectsCard
          title="Insurance Management System"
          des=" A Insurance Management system designed to Maintain Policies, Payment, Claims for Insurance Companies."
          src={projectTwo}
          websiteLink="#"
        />
        <ProjectsCard
          title="Radission Hotel Training"
          des=" This is a website for Radision Hotel Training."
          src={projectThree}
          websiteLink="https://radissonhoteltraining.com/"
        />
        <ProjectsCard
          title="Hostel Sewa"
          des=" A Hostel registration and booking side with account and hostel management system."
          src={projectFour}
          websiteLink="https://hostelsewa.com/"
        />
        <ProjectsCard
          title="Cake Station"
          des=" This is a cake website created using HTML,CSS,Bootstrap,C#,MVC,JQuery and Javascript."
          src={projectFive}
          websiteLink="https://www.celebrationcakes.com.np/"
        />
        <ProjectsCard
          title="HolidaySaathi"
          des=" This is Travel and Tours website used for booking different packages."
          src={projectSix}
          websiteLink="https://holidayssathi.com/"
        />
      </div>
    </section>
  );
}

export default Projects
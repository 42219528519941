import React from 'react'
import { SiFacebook, SiGithub, SiGmail, SiInstagram, SiLinkedin, SiTiktok, SiYoutube } from 'react-icons/si'
const Media = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me on
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
            <a href="https://np.linkedin.com/in/gopal-chudal" target="_blank" rel="noopener noreferrer">
              <SiLinkedin /></a>
            </span>
            <span className="bannerIcon">
            <a href="mailto:gopalchudal2013@gmail.com">
              <SiGmail /></a>
            </span>
            <span className="bannerIcon">
            <a href="https://github.com/GopalChudal" target="_blank" rel="noopener noreferrer">
              <SiGithub /></a>
            </span>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            OTHER LINKS
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <a href="https://www.youtube.com/channel/UCsMWSqkZrpM2Vgzi6p_r1rA" target="_blank" title="Ramailo Life's Experiences" rel="noopener noreferrer">
              <SiYoutube /></a>
            </span>
            <span className="bannerIcon">
              <a href="https://www.facebook.com/gopal.chudal.7" target="_blank" rel="noopener noreferrer">
              <SiFacebook /></a>
            </span>
            <span className="bannerIcon">
               <a href="https://www.instagram.com/gopalchudal2013/" target="_blank" rel="noopener noreferrer">
              <SiInstagram /></a>
            </span>
            <span className="bannerIcon">
               <a href="https://www.tiktok.com/@gopalchudal2" target="_blank" rel="noopener noreferrer">
              <SiTiktok /></a>
            </span>
          </div>
        </div>
      </div>
  )
}

export default Media